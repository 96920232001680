import { 
  IconBrandGithub, 
  IconBrandLeetcode, 
  IconBrandInstagram, 
  IconBrandLinkedin 
} from '@tabler/icons-react';
import React from 'react';

const Social = () => {
  const SocialLinks = [
    { links: "https://github.com/deepzsenu", icon: IconBrandGithub },
    { links: "https://www.linkedin.com/in/deepzsenu/", icon: IconBrandLinkedin },
    { links: "https://leetcode.com/u/deepzsenu/", icon: IconBrandLeetcode },
    // { links: "https://www.geeksforgeeks.org/user/deepzsenu/", icon: IconBrandGeeksforgeeks },
    { links: "https://www.instagram.com/deepsenu7/", icon: IconBrandInstagram }
  ];

  return (
    <div  className='flex md-mx:hidden text-textColor items-center gap-8 fixed bottom-32 -left-40 rotate-90 '>
      {SocialLinks.map((social, index) => (
        <a
          key={index}
          href={social.links}
          target="_blank"
          rel="noopener noreferrer"
          className='font-mono text-lg  hover:text-primaryColor hover:-translate-x-1 transition transform duration-300 ease-in-out'
        >
          <social.icon
            className='-rotate-90'
            size={30}
          />
        </a>
      ))}
      <hr className='border w-40 rounded-full  bg-textColor border-textColor' />
    </div>
  );
};

export default Social;
