import React, { useState, useEffect, useRef } from 'react';
import { Info, Docs } from '../../Constants/userData';
//@ts-ignore
import NET from 'vanta/dist/vanta.net.min';
import Typewriter from 'typewriter-effect';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Resume from '../ResumeSection/Resume';
import { NeonGradientCard } from '../magicui/neon-gradient-card';

const About = () => {
    const [opened, { close, open }] = useDisclosure(false);
    const [netEffect, setNetEffect] = useState<any>(null);
    const netRef = useRef<HTMLDivElement>(null);

    // Initialize Vanta.js NET effect
    useEffect(() => {
        if (!netEffect && netRef.current) {
            setNetEffect(NET({
                el: netRef.current,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.0,
                minWidth: 200.0,
                scale: 1.0,
                scaleMobile: 1.0,
                color: 0x122069,
                backgroundColor: 0x112240,
            }));
        }

        return () => {
            if (netEffect) netEffect.destroy();
        };
    }, [netEffect]);

    return (
        <div
            className="mt-24 sm:mt-28 md:mt-32 flex flex-col-reverse md:flex-row relative overflow-hidden justify-center items-center font-mono px-6 py-12 md:px-10 lg:px-14 gap-8 md:gap-10 h-fit"
            id="net-bg"
            ref={netRef}
        >
            {/* Content Section */}
            <div className="w-full md:w-3/5 flex flex-col items-center md:items-start text-center md:text-left">
                <div>
                    <div className="text-primaryColor text-xl sm:text-2xl">Hi, I am</div>
                    <div className="text-white text-4xl sm:text-5xl lg:text-6xl font-extrabold">{Info.name}</div>
                    <div className="text-white text-2xl sm:text-3xl lg:text-4xl font-semibold flex flex-wrap justify-center md:justify-start">
                        I'm a&nbsp;
                        <span className="text-primaryColor">
                            <Typewriter
                                options={{
                                    strings: Info.stack,
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </span>
                    </div>

                    <div className="text-textColor text-base sm:text-lg lg:text-xl mt-4 mb-6 md:mt-6 md:mb-8">
                        {Info.description}
                    </div>

                    <div className="flex flex-wrap gap-4 justify-center md:justify-start">
                        <Button
                            size="sm"
                            variant="filled"
                            onClick={open}
                            styles={{
                                root: {
                                    backgroundColor: '#64ffda',
                                    color: '#0a192f',
                                    '&:hover': {
                                        backgroundColor: '#52e6b6',
                                    },
                                },
                            }}
                        >
                            Check Resume
                        </Button>

                        <Button
                            component="a"
                            href={Docs.resume}
                            target="_blank"
                            size="sm"
                            variant="outline"
                            styles={{
                                root: {
                                    color: '#64ffda',
                                    borderColor: '#64ffda',
                                    '&:hover': {
                                        backgroundColor: '#64ffda',
                                        color: '#0a192f',
                                    },
                                },
                            }}
                            download
                        >
                            Download Resume
                        </Button>
                    </div>
                </div>
            </div>

            {/* Profile Image Section */}
            <div className="flex justify-center items-center">
                <NeonGradientCard className="w-64 h-64 sm:w-72 sm:h-72 lg:w-80 lg:h-80">
                    <img
                        className="w-full h-full rounded-full"
                        src={Docs.profileRemoveBg}
                        alt="profile"
                    />
                </NeonGradientCard>
            </div>

            {/* Resume Modal */}
            <Resume opened={opened} close={close} />
        </div>
    );
};

export default About;
