import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Drawer, Burger } from '@mantine/core';
import { navLinks } from './MenuOptions';

const SideBar = () => {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <>
      <Drawer.Root
        position="right"
        opened={opened}
        onClose={toggle}
        size="50vw"
        className="z-50"
      >
        <Drawer.Overlay className="!backdrop-opacity-50 blur-sm" />
        <Drawer.Content bg="#112240">
          <Drawer.Body className="mt-20 xs:mt-24 flex flex-col gap-5 text-white">
            {/* Pass an object instead of separate arguments */}
            {navLinks({ isSidebar: true, toggle })}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>

      <Burger
        size="md"
        color="#64FFDA"
        opened={opened}
        onClick={toggle}
        className="z-50"
      />
    </>
  );
};

export default SideBar;
