import React from 'react';
import { skillInfo } from '../../Constants/userData';
import SkillCard from './SkillCard';

const Skills = () => {
  return (
    <div
      className="px-6 sm:px-4 my-10 font-mono mx-auto"
      id="skills"
      style={{ maxWidth: '1200px', width: '95%' }}
    >
      <h1 className="text-4xl sm:text-3xl xs:text-2xl mb-10 font-bold text-center text-white">
        Skills
      </h1>
      <div className="flex flex-wrap justify-center gap-6">
        {skillInfo.map((skill: any, index: number) => (
          <SkillCard key={index} skillTitle={skill.title} skills={skill.skills} />
        ))}
      </div>
    </div>
  );
};

export default Skills;
