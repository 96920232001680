const validateForm = (field: string, value: string): string => {
    switch (field) {
      case 'name':
        if (!value.trim()) return 'Name is required';
        break;
      case 'email':
        if (!value.trim()) return 'Email is required';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) return 'Invalid email format';
        break;
      case 'phone':
        if (!value.trim()) return 'Phone number is required';
        const phoneRegex = /^\d{10}$/; // Adjust regex according to your requirement
        if (!phoneRegex.test(value)) return 'Invalid phone number format';
        break;
      case 'message':
        if (!value.trim()) return 'Message is required';
        break;
      default:
        return '';
    }
    return '';
  };
  export default validateForm;