import React from 'react'
// import { Timeline } from '@mantine/core';
import { experienceInfo } from '../../Constants/userData';
import TimeLineItems from './TimeLineItems';
import { Text, Timeline, useMatches } from "@mantine/core";

const Experience = (props: any) => {
    const size=useMatches({
        xs:15,
        md:20,
    })
    const dot=useMatches({
        xs:25,
        md:30,
    })
    return (
        <div
        
         className='px-16 mx-20 md-mx:px-6 sm-mx:px-2 lg-mx:mx-0 my-10 mb-28 font-mono' id="experience">
            <h1 className='text-4xl sm-mx:text-3xl xs-mx:text-2xl  mb-10 font-bold text-center text-white'>
                Experience
            </h1>

            <Timeline color="#64FFDA" active={5} bulletSize={dot} lineWidth={2}>

                <TimeLineItems
                    items={experienceInfo}
                />

            </Timeline>
        </div>
    )
}

export default Experience