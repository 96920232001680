import React from 'react';

const links = ["About", "Skills", "Projects", "Experience", "Contact"];

// Define types for the function parameters
type NavLinksProps = {
  isSidebar: boolean;
  toggle?: () => void;
};

// Function to render navigation links with proper type annotations
export const navLinks = ({ isSidebar, toggle }: NavLinksProps) => {
  return links.map((link, index) => (
    <React.Fragment key={link}>
      <a
        className={`capitalize transition-colors duration-300 ${
          isSidebar
            ? 'block mb-2 text-lg font-semibold text-gray-300 hover:text-primaryColor'
            : 'inline-block text-lg font-semibold text-textColor hover:text-primaryColor'
        }`}
        href={`#${link.toLowerCase()}`}
        onClick={isSidebar && toggle ? toggle : undefined}
      >
        {link}
      </a>
      {!isSidebar && index < links.length - 1 && (
        <span className="hidden md:inline text-md font-semibold mx-2 text-primaryColor">
          |
        </span>
      )}
    </React.Fragment>
  ));
};

// Define types for the component props
interface MenuOptionsProps {
  isSidebar?: boolean;
}

// Component rendering the menu options
export const MenuOptions = ({ isSidebar = false }: MenuOptionsProps) => {
  return (
    <div
      className={`flex ${
        isSidebar
          ? 'flex-col mt-4 mb-4'
          : 'items-center gap-2 md:gap-1 mt-2 mb-2'
      } text-textColor ${isSidebar ? 'text-lg' : 'hidden md:flex'}`}
    >
      {navLinks({ isSidebar })}
    </div>
  );
};

export default MenuOptions;
