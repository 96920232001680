import React from 'react';
import ProjectCard from './ProjectCard';
import { ProjectInfo } from '../../Constants/ProjectData';

const Projects = () => {
  return (
    <div
      className='px-0 sm:px-0 font-mono mt-10 mx-auto'
      id="projects"
      style={{ maxWidth: '1200px', width: '80%' }}
    >
      <h1 className='text-4xl sm:text-3xl xs:text-2xl text-white font-bold text-center mb-8'>
        Projects
      </h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {ProjectInfo.map((project: any, index: number) => (
          <ProjectCard
            key={index}
            title={project.title}
            desc={project.desc}
            image={project.image}
            live={project.live}
            liveLink={project.liveLink}
            githubCode={project.githubCode}
            techUsed={project.techUsed}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
