import React from 'react';
import { Modal, Button, Group, Image, Badge } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import StatusIndicator from './Indicator';

const ProjectModal = (props: any) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isMobileScreen = useMediaQuery('(max-width: 576px)');
  const modalWidth = isMobileScreen ? '90%' : isSmallScreen ? '70%' : '50%';

  return (
    <Modal.Root
      opened={props.opened}
      onClose={props.close}
      size="auto"
      styles={{
        content: {
          width: modalWidth,
          overflowY: 'auto',
        },
      }}
    >
      <Modal.Overlay />
      <Modal.Content className='!rounded-3xl !border-primaryColor !border-2 !font-mono'>
        <Modal.Header className='!bg-bgColor !text-primaryColor !font-bold !items-center '>
          <Modal.Title>
            <span className='text-2xl'>{props.projTitle}&nbsp;</span>
            <Badge
              color={props.isLive ? "green" : "red"}
              variant="outline"
              rightSection={<StatusIndicator live={props.isLive} />}
            >
              {props.isLive ? "Live" : "Not Live"}
            </Badge>
          </Modal.Title>
          <Modal.CloseButton
            size="md"
            iconSize="30px"
            className='!bg-bgColor !text-red-600'
            color="red"
          />
        </Modal.Header>

        <Modal.Body className='bg-bgColor text-textColor'>
          <div style={{ display: 'flex', justifyContent: 'center', height: "300px" }}>
            <Image
              className='!rounded-xl shadow-[0_0_5px_0_#64ffda]'
              src={props.image}
              alt={props.title}
              fit="cover"
              height="300px"
              width="100%"
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
          <Group className='mt-6 mb-2 flex-wrap ml-6 '>
            {props.techUsed.map((tech: string, index: number) => (
              <Badge
                className='!text-bgColor'
                key={index}
                color='#64ffda'
              >
                {tech}
              </Badge>
            ))}
          </Group>

          <div className="pl-6 mt-2">
            <p className='text-justify'>{props.projDesc}</p>
          </div>

          <Group justify='center' className="mb-4 mt-6">
            {props.isLive && (
              <Button
                className='!bg-primaryColor !text-bgColor'
                component="a"
                href={props.liveLink}
                target="_blank"
                mt="md"
              >
                View Live
              </Button>
            )}
            {props.repoLink !== "" && (
              <Button
                className='!bg-primaryColor !text-bgColor'
                component="a"
                href={props.repoLink}
                target="_blank"
                mt="md"
              >
                View Code
              </Button>
            )}
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ProjectModal;
