import React, { useEffect, useState } from 'react'
import Header from './Header/Header'
import Mail from './SideComponents/Mail'
import Social from './SideComponents/Social'
import About from './About/About'
import Projects from './Projects/Projects'
import Skills from './Skills/Skills'
import Experience from './Experience/Experience'
import Contact from './Contact/Contact'
import Footer from './Footer/Footer'
import { Loader } from './Loader'
// import {Loader }from "./Loader"
const Home = () => {
  const [loading , setLoading] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false);
    }, 800)
  }, [])

  return (
    <div  className={` focus-visible:[&_button]:!outline-none min-h-[100dvh] ${loading?"flex":""} items-center overflow-hidden justify-center`}>
    {loading !== true?
    <>
      <Header />
      <About/>
      <Mail />
      <Social/>
      <Skills/>
      <Projects/>
      <Experience/>
      <Contact/>
      <Footer/>
      </>:<Loader/>
      }
    </div>
  )
}

export default Home