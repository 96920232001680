import React from 'react';
import { Avatar } from '@mantine/core';

interface SkillBadgeProps {
  skills: string[];
}

const SkillBadge: React.FC<SkillBadgeProps> = ({ skills }) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      className="flex flex-wrap justify-center gap-2"
    >
      {skills.map((skill: string, index: number) => (
        <div
          key={index}
          className="flex items-center gap-2 border border-textColor p-2 rounded-2xl"
        >
          <Avatar
            variant="transparent"
            radius="xs"
            src={`/icons/${skill.toLowerCase().replace(/\s+/g, '')}.png`}
            alt={skill}
            size={24}
          />
          <div className="text-textColor text-lg font-medium">{skill}</div>
        </div>
      ))}
    </div>
  );
};

const SkillCard = (props: any) => {
  return (
    <div
      className="w-full sm:w-[48%] md:w-[30%] lg:w-[23%] border rounded-xl border-primaryColor p-5 mb-5 flex flex-col shadow-[0_0_5px_0_#64ffda]"
    >
      <div className="text-2xl text-white text-center font-bold mb-4">
        {props.skillTitle}
      </div>
      <SkillBadge skills={props.skills} />
    </div>
  );
};

export default SkillCard;
