// Header.jsx
import React, { useEffect, useState } from 'react';
import { IconHexagonLetterD } from '@tabler/icons-react';
import SideBar from './SideBar';
import MenuOptions from './MenuOptions';
import { useMediaQuery } from '@mantine/hooks';
import { em } from '@mantine/core';

const Header = () => {
  const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [shadow, setShadow] = useState(false);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY && window.scrollY > 70) setShow(false);
    else setShow(true);

    setShadow(window.scrollY > 70);
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => window.removeEventListener('scroll', controlNavbar);
  }, [lastScrollY]);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 bg-bgColor h-16 md:h-20 px-4 md:px-8 transition-transform duration-500 ease-in-out ${
        show ? 'translate-y-0' : '-translate-y-full'
      } ${shadow ? 'shadow-lg' : ''}`}
    >
      <div className="flex justify-between items-center h-full max-w-7xl mx-auto">
        <div className="flex items-center">
          <IconHexagonLetterD
            className="z-10"
            size={isMobile ? 36 : 60}
            color="#64FFDA"
            stroke={1.25}
          />
          {!isMobile && (
            <span className="text-primaryColor text-lg ml-2 font-semibold">
              My Portfolio
            </span>
          )}
        </div>

        <div className="hidden md:flex">
          <MenuOptions />
        </div>

        <div className="md:hidden">
          <SideBar />
        </div>
      </div>
    </nav>
  );
};

export default Header;